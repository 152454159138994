<template>
  <div>
    <div v-if="company">
      <v-row>
        <v-col cols="12" sm="auto">
          <h1 class="text-left">{{ company.name }}</h1>
          <h2 class="text-left">{{ company.companyName }}</h2>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn :block="$vuetify.breakpoint.mobile" depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="editCompany">
            <v-icon left>mdi-pencil-outline</v-icon>
            Edit
          </v-btn>
          <v-btn
            :block="$vuetify.breakpoint.mobile"
            color="error"
            outlined
            depressed
            class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0"
            v-if="isAdmin"
            @click="deleteCompany"
          >
            <v-icon left>mdi-delete</v-icon>
            Delete
          </v-btn>
        </v-col>
      </v-row>

      <v-simple-table dense class="my-4">
        <tbody>
          <tr>
            <td class="font-weight-bold">Id:</td>
            <td>{{ company.id }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Account number:</td>
            <td>{{ company.accountNumber }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Department:</td>
            <td>{{ company.department }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Country:</td>
            <td>{{ company.country }}</td>
          </tr>
          <tr v-if="company.usersCount">
            <td class="font-weight-bold">Users:</td>
            <td>{{ company.usersCount }}</td>
          </tr>
          <tr v-if="company.licenseId">
            <td class="font-weight-bold">License:</td>
            <td>
              <router-link :to="{ name: 'LicenseDetails', params: { Id: company.licenseId } }">{{ company.licenseName }}</router-link>
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">Last updated:</td>
            <td><last-updated-field :item="company" /></td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="mt-8">
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="auto">
            <h3>Users</h3>
          </v-col>
          <v-col>
            <v-btn :block="$vuetify.breakpoint.mobile" depressed class="align-self-center ml-md-4 mb-3 mb-md-0" @click="addUser()">
              <v-icon left>mdi-pencil-outline</v-icon>
              Add user
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-checkbox v-model="showAllUsers" hide-details label="Show disabled" />
          </v-col>
        </v-row>
        <v-data-table :headers="userHeaders" :items="filteredUsers" @click:row="showUserDetails" v-bind="$util.dataTableAttributes()">
          <template #[`item.name`]="{ item }">
            <router-link :to="{ name: 'UserDetails', params: { Id: item.id } }">{{ item.name }}</router-link>
          </template>
          <template #[`item.disabled`]="{ item }">
            <readonly-checkbox :value="!item.disabled" />
          </template>
          <template #[`item.prereleaseEnabled`]="{ item }">
            <readonly-checkbox :value="item.prereleaseEnabled" />
          </template>
        </v-data-table>
      </div>
    </div>
    <create-edit-company ref="CreateEditCompanyRef" @company-updated="bubbleEmit" />
    <create-edit-user ref="CreateEditUserRef" @user-updated="getCompany" />
  </div>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditCompany from '@/components/Admin/Dialogs/CreateEditCompany.vue';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';
import CreateEditUser from '@/components/Admin/Dialogs/CreateEditUser.vue';
import { mapGetters } from 'vuex';

export default {
  components: { CreateEditCompany, ReadonlyCheckbox, CreateEditUser },
  name: 'CompanyDetails',
  data: () => ({
    loading: true,
    showAllUsers: false,
    company: {},
    userHeaders: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'E-mail',
        value: 'email',
      },
      {
        text: 'Enabled',
        value: 'disabled',
      },
      {
        text: 'Prerelease',
        value: 'prereleaseEnabled',
      },
      {
        text: 'Phone',
        value: 'phoneNumber',
      },
      {
        text: 'Roles',
        value: 'roles',
      },
    ],
  }),
  computed: {
    ...mapGetters(['IsAdmin']),
    filteredUsers() {
      return this.showAllUsers ? this.company?.users : this.company?.users?.filter((x) => !x.disabled);
    },
  },
  methods: {
    async getCompany() {
      this.loading = true;
      this.company = await apiService.getCompanyById(this.$route.params.Id);
      this.loading = false;
    },
    async editCompany() {
      this.$refs.CreateEditCompanyRef.editCompany(this.company);
    },
    bubbleEmit() {
      this.getCompany().then(() => {
        this.$emit('company-updated');
      });
    },
    showUserDetails(user) {
      this.$router.push({ name: 'UserDetails', params: { Id: user.id } });
    },
    addUser() {
      this.$refs.CreateEditUserRef.createUser({ companyId: this.company.id });
    },
    async deleteCompany() {
      if (this.company.users.length) {
        alert(`Cannot delete company as it contains ${this.company.users.length} users. The users must be deleted before the company can be deleted.`);
      } else {
        if (confirm('Are you sure you want to delete this company? This cannot be undone.')) {
          await apiService.deleteCompany(this.company.id);
          this.$router.back();
        }
      }
    },
  },
  created() {
    this.getCompany();
  },
};
</script>

<style></style>
